import sendRequestAndGetResponse from './sendRequestAndGetResponse';

const BASE_PATH = '/payment';

export const getStripeConnectURL = () => sendRequestAndGetResponse(`${BASE_PATH}/get-connect-link`);

export const confirmStripeConnectComplete = () =>
  sendRequestAndGetResponse(`${BASE_PATH}/get-connect-link`);

export const updatePaymentMethod = (paymentMethod) =>
  sendRequestAndGetResponse(`${BASE_PATH}/method/update`, {
    method: 'POST',
    body: JSON.stringify({ paymentMethod: paymentMethod }),
  });

export const createPaymentIntent = () =>
  sendRequestAndGetResponse(`${BASE_PATH}/create-payment-intent`);
/*
export const sendDataToLambdaApiMethod = (data) =>
  sendRequestAndGetResponse(`${process.env.API_GATEWAY_ENDPOINT}/`, {
    externalServer: true,
    body: JSON.stringify(data),
  });
*/

export const disconnect = () =>
  sendRequestAndGetResponse(`${BASE_PATH}/disconnect`, { method: 'POST' });
