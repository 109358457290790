import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { Provider } from 'mobx-react';
import App from 'next/app';
import React from 'react';

import { themeDark, themeLight } from '../lib/theme';
import { getUserApiMethod } from '../lib/api/public';
import { getInitialDataApiMethod } from '../lib/api/team-member';
import { isMobile } from '../lib/isMobile';
import { getStore, initializeStore, Store } from '../lib/store';
import Head from 'next/head';
import { Dialog, DialogContent } from '@material-ui/core';
import AttendeeWelcome from 'components/dashboard/AttendeeWelcome';

export function getSubdomain(req) {
  let host;
  let sub;
  if (req && req.headers.host) {
    host = req.headers.host;
  }
  if (typeof window !== 'undefined') {
    host = window.location.host;
  }
  if (host) {
    sub = host.split('localhost:3000')[0];
    if (sub) {
      return sub.split('.')[0];
    }
  }
}
class MyApp extends App<{ isMobile: boolean }> {
  public static async getInitialProps({ Component, ctx }) {
    let firstGridItem = true;
    let teamRequired = false;
    let exposeSearchParams = false;

    if (
      ctx.pathname.includes('/login') ||
      ctx.pathname.includes('/create-team') ||
      ctx.pathname.includes('/invitation') ||
      ctx.pathname.includes('/teacher') ||
      ctx.pathname.includes('/search')
    ) {
      firstGridItem = false;
    }

    if (
      ctx.pathname.includes('/team-settings') ||
      ctx.pathname.includes('/discussion') ||
      ctx.pathname.includes('/billing')
    ) {
      teamRequired = true;
    }

    if (ctx.pathname.includes('/search')) {
      exposeSearchParams = true;
    }

    /*
    const domain = getSubdomain(ctx.req);
    if (domain && false) {
      console.log('domain ' + domain);
    }
    */

    const { teamSlug, redirectMessage, discussionSlug } = ctx.query;

    let pageProps = {
      isMobile: isMobile({ req: ctx.req }),
      firstGridItem,
      teamRequired,
      teamSlug,
      redirectMessage,
      discussionSlug,
    };

    if (exposeSearchParams) {
      const queryCopy = Object.assign({}, ctx.query);
      pageProps = Object.assign(queryCopy, pageProps); //we use page props on top of query copy because we don't want someone to overwrite our starter page props with user input!!!
    }

    if (Component.getInitialProps) {
      Object.assign(pageProps, await Component.getInitialProps(ctx));
    }

    const appProps = { pageProps };

    const store = getStore();
    if (store) {
      return appProps;
    }

    const { req } = ctx;

    const headers: any = {};
    if (req.headers && req.headers.cookie) {
      headers.cookie = req.headers.cookie;
    }

    let userObj = null;
    try {
      const { user } = await getUserApiMethod({ headers });
      userObj = user;
    } catch (error) {
      console.error(error);
    }

    let initialData = {};

    if (userObj) {
      try {
        initialData = await getInitialDataApiMethod({
          request: ctx.req,
          data: { teamSlug, discussionSlug },
        });
      } catch (error) {
        console.error(error);
      }
    }

    return {
      ...appProps,
      initialState: {
        user: userObj,
        currentUrl: ctx.asPath,
        teamSlug,
        ...initialData,
        isMobile: pageProps.isMobile,
      },
    };
  }

  public componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }

  private store: Store;

  constructor(props) {
    super(props);

    //console.log('MyApp.constructor');

    this.store = initializeStore(props.initialState);
  }

  public render() {
    const { Component, pageProps } = this.props;
    const store = this.store;

    const handleClose = async () => {
      await store.currentUser.markSeenWelcomeMessage();
    };

    return (
      <>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Head>
        <ThemeProvider
          theme={store.currentUser && store.currentUser.darkTheme ? themeDark : themeLight}
        >
          <CssBaseline />
          <Provider store={store}>
            <Component {...pageProps} store={store} />
            {store.currentUser && !store.currentUser.seenWelcomeMessage && (
              <Dialog
                open={store.currentUser && !store.currentUser.seenWelcomeMessage}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullScreen={true}
              >
                <DialogContent style={{ padding: '0px' }}>
                  <AttendeeWelcome onClosed={handleClose} />
                </DialogContent>
              </Dialog>
            )}
          </Provider>
        </ThemeProvider>
      </>
    );
  }
}

export default MyApp;
