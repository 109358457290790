import { Button, CardContent, CardHeader, Typography } from '@material-ui/core';
import { useState } from 'react';
import { maxWidth } from 'utils/constants';

//https://images.unsplash.com/photo-1576267423445-b2e0074d68a4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80

const welcomePhoto = 'https://images.unsplash.com/photo-1600577916048-804c9191e36c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80'

const AttendeeWelcome = ({ onClosed, fromScreen }: { onClosed?: Function, fromScreen? : string }) => {
  const [dismissed, setDismissed] = useState(false);
  return (
    <div>
      {!dismissed && (
        <div style={{ marginBottom: '10px' }}>
          <CardHeader style={{ textAlign: 'center' }} title="Welcome to Augment ED"></CardHeader>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
              style={{ margin: 'auto', maxWidth: '100%' }}
              src={welcomePhoto}
            />
          </div>
          <CardContent>
            <div style={{ maxWidth, margin: 'auto' }}>
              <Typography variant="body1" >
                We're here to connect parents and students with passionate experienced teachers. Our
                focus is to provide the tools to make this as simple and safe as possible while
                empowering educators to do their best work.
              </Typography>
              <br />
              <Typography variant="body1" style={{ maxWidth }}>
                We're excited that you've decided to join us and we're here to help you on your
                education journey
              </Typography>
            </div>
            <div style={{ display: 'flex' }}>
              <Button
                style={{ marginLeft: 'auto' }}
                onClick={() => {
                  if(!fromScreen)setDismissed(true);
                  onClosed && onClosed();
                }}
                variant={'contained'}
                color={'primary'}
              >
                Lets go
              </Button>
            </div>
          </CardContent>
        </div>
      )}
    </div>
  );
};

export default AttendeeWelcome;
