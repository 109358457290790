import cookie from 'cookie';
import fetch from 'isomorphic-unfetch';
import { makeQueryString } from './makeQueryString';

export default async function sendRequestAndGetResponse(path, opts: any = {}) {
  const headers = Object.assign(
    {},
    opts.headers || {},
    opts.externalServer
      ? {}
      : {
          'Content-type': 'application/json; charset=UTF-8',
        },
  );

  const { request } = opts;

  if (request && request.headers && request.headers.cookie) {
    headers.cookie = request.headers.cookie;
  }

  let token;
  if (typeof window !== 'undefined') {
    token = document.cookie && `${cookie.parse(document.cookie).token}`;
  } else {
    token = headers.cookie && `${cookie.parse(headers.cookie).token}`;
  }
  // Set the token only if available otherwise it gives the error.
  token ? (headers.token = token) : '';

  const qs = (opts.qs && `?${makeQueryString(opts.qs)}`) || '';

  const url = process.env.URL_API;
  if (!url) {
    throw new Error(
      `'process.env.URL_API' ${process.env.URL_API} not set properly. cannot proceed`,
    );
  }

  //console.log(`before: ${path}${qs}`);

  const response = await fetch(
    opts.externalServer ? `${path}${qs}` : `${url}${path}${qs}`,
    Object.assign({ method: 'POST', credentials: 'include' }, opts, { headers }),
  );

  //console.log(`after: ${process.env.URL_API}${path}${qs}`);

  //console.log(response.status);
  //console.log(response.statusText);

  const text = await response.text();

  if(response.status == 401 && path === '/api/v1/public/get-user' && text == 'Unauthorized') {
    //noop on purpose in this case
    console.log('401 on /api/v1/public/get-user');
  } else if (response.status >= 400) {
    console.log(`Request Problem ${response.status} - ${path}`);
    let parsed = text;
    try {
      parsed = JSON.parse(text).error;
    } catch (e) {
    } finally {
      console.error(`could not parse error: ${parsed} - ${path}`);
      throw new Error(parsed);
    }
  }

  try {
    return JSON.parse(text);
  } catch (err) {
    if (err instanceof SyntaxError) {
      return text;
    }
    throw err;
  }

}
