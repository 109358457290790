import { IncomingMessage } from 'http';
import sendRequestAndGetResponse from './sendRequestAndGetResponse';

const BASE_PATH = '/meeting';

export const getMeetingListForAttendee = (userId) =>
  sendRequestAndGetResponse(`${BASE_PATH}/get-list-for-attendee`, {
    qs: { userId },
    method: 'GET',
  });

export const getMeetingListForCreator = (userId: string, fetchPastMeetings: boolean) =>
  sendRequestAndGetResponse(`${BASE_PATH}/get-list-for-creator`, {
    qs: { userId, fetchPastMeetings },
    method: 'GET',
  });

export const getMeetingDetail = (meetingId: string) =>
  sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}`, {
    method: 'GET',
  });

export const getMeetingDetailBySlug = (meetingSlug: string, createdBy: string) =>
  sendRequestAndGetResponse(`${BASE_PATH}/e`, {
    qs: { meetingSlug, createdBy },
    method: 'GET',
  });

export const getMeetingPathFromId = (meetingId: string, req: IncomingMessage) =>
  sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/getPath`, {
    method: 'GET',
    request: req,
  });

export const getMeetingBySlugByUserSlug = (
  userSlug: string,
  meetingSlug: string,
  req: IncomingMessage,
) =>
  sendRequestAndGetResponse(`${BASE_PATH}/e`, {
    qs: { meetingSlug, userSlug },
    method: 'GET',
    request: req,
  });

export const authorizePaymentForMeeting = (meetingId: string, addMeToMeeting: boolean) => {
  return sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/authorizePayment`, {
    method: 'POST',
    body: JSON.stringify({ addMeToMeeting }),
  });
};

export const confirmPlaceForMeeting = (meetingId: string, addMeToMeeting: boolean) => {
  return sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/confirmPlace`, {
    method: 'POST',
    body: JSON.stringify({ addMeToMeeting }),
  });
};

export const attemptRepair = (meetingId: string) => {
  return sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/repair`, {
    method: 'POST',
  });
};

export const emailAttendanceReport = (meetingId: string) => {
  return sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/emailAttendanceReport`, {
    method: 'POST',
  });
};

export const emailPostMeetingReport = (meetingId: string) => {
  return sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/emailPostMeetingReport`, {
    method: 'POST',
  });
};

export const SendFeedback = (meetingId: string, userId: string, feedbackMsg: string) =>
  sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/addStudentFeedback`, {
    method: 'POST',
    body: JSON.stringify({ userId, feedbackMsg }),
  });

export const addAttendeeToMeeting = (meetingId: string, emails: string[]) =>
  sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/addMembers`, {
    method: 'POST',
    body: JSON.stringify({ emails }),
  });

export const removeAttendeeFromMeeting = (meetingId: string, memberId: string) =>
  sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/removeMember`, {
    method: 'POST',
    body: JSON.stringify({ memberId }),
  });

export const toggleEnableRecording = (meetingId: string) =>
  sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/toggleEnableRecording`, {
    method: 'POST',
  });

export const sendMessageToMeetingAttendees = (
  meetingId: string,
  msg: string,
  confirmedOnly: boolean,
) => {
  return sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/messageAttendees`, {
    method: 'POST',
    body: JSON.stringify({ msg, confirmedOnly }),
  });
};

export const getAdminMeetingDetails = (meetingId: string) =>
  sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/admin`, { method: 'GET' });

export const UpdateMeeting = (
  meetingId: string,
  learningGoals: string,
  customConfirmationMessage: string,
) =>
  sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/`, {
    method: 'PUT',
    body: JSON.stringify({ learningGoals, customConfirmationMessage }),
  });

export const getClassesToBrowse = (publicOnly: boolean, forCreator: string, includeFull: boolean) =>
  sendRequestAndGetResponse(`${BASE_PATH}/browse`, {
    method: 'POST',
    body: JSON.stringify({ publicOnly, forCreator, includeFull }),
  });

export const updateImageApiMethod = (meetingId: string, newUrl: string) =>
  sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/image`, {
    method: 'POST',
    body: JSON.stringify({ newUrl }),
  });

export const updateTopic = (meetingId: string, newTopic: string) =>
  sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/topic`, {
    method: 'POST',
    body: JSON.stringify({ newTopic }),
  });

  export const updatePhotos = (meetingId: string, photos: any) =>
  sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/update`, {
    method: 'PATCH',
    body: JSON.stringify({ photos }),
  });

export const sendFeedbackForClass = (meetingId: string, message: string) =>
  sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/addClassFeedback`, {
    method: 'POST',
    body: JSON.stringify({ message }),
  });

export const requestJoinMeeting = (meetingId: string) =>
  sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/requestJoin`, {
    method: 'GET',
  });

export const convertToTemplate = (meetingId: string) =>
  sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/convertToTemplate`, {
    method: 'POST',
  });

export const update = (meetingId: string, params: object) =>
  sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/update`, {
    method: 'PATCH',
    body: JSON.stringify(params),
  });

  //function to regenerate the meeting invoice
export const regenerateInvoice = (meetingId: string) =>
  sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/regenerateInvoice`, {
    method: 'POST',
  });

  
export const changeBillingEmail = (meetingId: string, email: string) =>
  sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/changeBillingEmail`, {
    method: 'POST',
    body: JSON.stringify({ email }),
  });

  export const rescheduleMeeting = (meetingId: string, newScheduledAt: string, note:string, newDuration:number, shouldMessageAttendees:boolean) =>
    sendRequestAndGetResponse(`${BASE_PATH}/${meetingId}/rescheduleMeeting`, {
      method: 'POST',
      body: JSON.stringify({ newScheduledAt, note, newDuration, shouldMessageAttendees }),
    });
    