
export const MEETING_STATE = {
  CREATED: 'created',
  STARTED: 'started',
  FINISHED: 'finished',
  EXTERNAL: 'external',
};

export const maxWidth = '1024px';

export interface FileUploadTuple {
  file?: File;
  url?: string;
}

export interface PhotoUploadResults {
  url: string;
  order: number;
}

export interface ImageUploadResults {
  backgroundImage: string;
  photos: PhotoUploadResults[]
}

//mirrored in models/Meeting.ts
export enum PaymentType {
  Free = 'free',                        //no one pays
  PaidParticipant = 'paid_participant', //each participant pays
  Invoice = 'paid_invoice',             //someone is sent an invoice
}

export enum RateType {
  FlatRate = 'flat_rate',               //rate is a flat rate
  PerParticipant = 'per_participant',   //rate is per participant
}

export interface ClassTemplate {
  _id: string;
  createdBy: string;
  createdAt: Date
  topic: string;
  duration: number;
  requirements: string;
  learningGoals: string;
  backgroundImage: string;
  fromSuggestion: string;
  fromClass: string;
  type: string;
  billingInfo: {
    rateType: RateType;
    paymentType: PaymentType;
    rate?: number;
    billTo?: string;
  }
  photos: { url: string; order: number }[];
}