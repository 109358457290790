import { action, makeObservable, observable, runInAction } from 'mobx';
import { disconnect as stripeDisconnect } from '../api/payments';
import { disconnect as zoomDisconnect } from '../api/video';

import { getListOfInvoicesApiMethod } from '../api/team-leader';
import {
  toggleThemeApiMethod,
  updateProfileApiMethod,
  updateTeacherProfileApiMethod
} from '../api/team-member';
import { markSeenWelcomeMessage } from '../api/user';
import { Store } from './index';

interface Quests {
  hasApplication: boolean,
  hasClassTemplate: boolean,
  hasPublicProfile: boolean,
  hasUmbrellaPolicy: boolean,
  hasBackgroundCheck: boolean,
  qualifiedCount: number,
};


class User {
  public store: Store;

  public _id: string;
  public slug?: string = null;
  public email?: string = null;
  public displayName?: string = null;
  public avatarUrl?: string = null;
  public isSignedupViaGoogle: boolean;

  public firstName: string;
  public lastName: string;
  public fullName: string;

  public zoomAccount: string | null;
  public stripeAccount: string | null;
  public quests?: Quests = null;

  public darkTheme = false;
  public defaultTeamSlug?: string = null;

  public stripeCard: {
    card: {
      brand: string;
      funding: string;
      last4: string;
      exp_month: number;
      exp_year: number;
    };
  } | null = null;
  public hasCardInformation: boolean;
  public stripeListOfInvoices: {
    object: string;
    data: [
      {
        amount_paid: number;
        teamName: string;
        created: number;
        hosted_invoice_url: string;
      }
    ];
    has_more: boolean;
  } | null = null;
  public hasStripeConnected: boolean;
  public hasZoomConnected: boolean;
  public roles: string[];

  //teacher only params - moving to application (which is teacher profile)
  public quote: string;
  public videoUrl: string;
  public bio: string;
  public degree: string;
  public award: string;
  public seenWelcomeMessage: boolean = false;

  constructor(params) {
    makeObservable(this, {
      slug: observable,
      email: observable,
      displayName: observable,
      avatarUrl: observable,
      darkTheme: observable,
      defaultTeamSlug: observable,
      stripeCard: observable,
      stripeListOfInvoices: observable,
      seenWelcomeMessage: observable,
      updateProfile: action,
      toggleTheme: action,
      getListOfInvoices: action,
      markSeenWelcomeMessage: action,
      quests: observable,
    });
    this.store = params.store;
    this._id = params._id;
    this.slug = params.slug;
    this.email = params.email;
    this.displayName = params.displayName;
    this.avatarUrl = params.avatarUrl;
    this.isSignedupViaGoogle = !!params.isSignedupViaGoogle;
    this.darkTheme = !!params.darkTheme;
    this.defaultTeamSlug = params.defaultTeamSlug;

    this.stripeCard = params.stripeCard;
    this.hasCardInformation = params.hasCardInformation;
    this.stripeListOfInvoices = params.stripeListOfInvoices;

    this.firstName = params.firstName;
    this.lastName = params.lastName;
    this.fullName = `${this.firstName ? this.firstName : ''} ${
      this.lastName ? this.lastName : ''
    }`;

    this.hasZoomConnected = params.hasZoomConnected;
    this.hasStripeConnected = params.hasStripeConnected;
    this.roles = params.roles;

    this.seenWelcomeMessage = params.hasSeenWelcomeMessage;
    this.quests = params.quests;
  }

  public async disconnectStripe() {
    await stripeDisconnect();
    runInAction(() => {
      this.hasStripeConnected = false;
    });
  }

  public async markSeenWelcomeMessage() {
    await markSeenWelcomeMessage();
    runInAction(() => {
      this.seenWelcomeMessage = true;
      window.location.reload();
    });
  }

  public async disconnectZoom() {
    await zoomDisconnect();
    runInAction(() => {
      this.hasZoomConnected = false;
    });
  }

  public async updateName({
    firstName,
    lastName,
  }: {
    firstName: string;
    lastName: string;
  }) {
    const { updatedUser } = await updateProfileApiMethod({
      firstName,
      lastName,
    });

    runInAction(() => {
      this.firstName = updatedUser.firstName;
      this.lastName = updatedUser.lastName;
    });
  }

  public async updateTeacherProfile({
    quote,
    videoUrl,
    award,
    bio,
    degree,
  }: {
    quote: string;
    videoUrl: string;
    bio: string;
    award: string;
    degree: string;
  }) {
    const { updatedProfile } = await updateTeacherProfileApiMethod({
      quote,
      videoUrl,
      bio,
      award,
      degree,
    });

    runInAction(() => {
      this.quote = updatedProfile.quote;
      this.videoUrl = updatedProfile.videoUrl;
      this.bio = updatedProfile.bio;
      this.degree = updatedProfile.degree;
      this.award = updatedProfile.award;
    });
  }

  public async updateProfile({
    firstName,
    lastName,
    avatarUrl,
  }: {
    firstName: string;
    lastName: string;
    avatarUrl: string;
  }) {
    const { updatedUser } = await updateProfileApiMethod({
      firstName,
      lastName,
      avatarUrl,
    });

    runInAction(() => {
      this.displayName = updatedUser.displayName;
      this.avatarUrl = updatedUser.avatarUrl;
      this.slug = updatedUser.slug;
    });
  }

  public async toggleTheme(darkTheme: boolean) {
    await toggleThemeApiMethod({ darkTheme });
    runInAction(() => {
      this.darkTheme = darkTheme;
    });
    window.location.reload();
  }

  public async getListOfInvoices() {
    try {
      const { stripeListOfInvoices } = await getListOfInvoicesApiMethod();
      runInAction(() => {
        this.stripeListOfInvoices = stripeListOfInvoices;
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export { User };
