import sendRequestAndGetResponse from './sendRequestAndGetResponse';

const BASE_PATH = '/user';

export const getUser = (userId) =>
  sendRequestAndGetResponse(`${BASE_PATH}/get`, { qs: { userId }, method: 'GET' });

export const getPublicProfileById = (userId) =>
  sendRequestAndGetResponse(`${BASE_PATH}/publicProfile`, { qs: { userId }, method: 'GET' });

export const getPublicProfileBySlug = (slug) =>
  sendRequestAndGetResponse(`${BASE_PATH}/publicProfile`, { qs: { slug }, method: 'GET' });

export const getUpcomingPublicClasses = (userId) =>
  sendRequestAndGetResponse(`${BASE_PATH}/${userId}/upcomingClasses`, { method: 'GET' });

export const getClassesCount = (userId: string) =>
  sendRequestAndGetResponse(`${BASE_PATH}/${userId}/classesCount`, {
    qs: { userId },
    method: 'GET',
  });

export const markSeenWelcomeMessage = () =>
  sendRequestAndGetResponse(`${BASE_PATH}/markSeenWelcomeMessage`, { method: 'POST' });

export const getInvoices = () =>
  sendRequestAndGetResponse(`${BASE_PATH}/invoices`, { method: 'GET' });
