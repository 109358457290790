import { FinalizedCreateMeetingParams } from 'components/meeting/CreateMeetingModal';
import sendRequestAndGetResponse from './sendRequestAndGetResponse';

const BASE_PATH = '/video';

export const getZoomConnectURL = () => sendRequestAndGetResponse(`${BASE_PATH}/get-connect-link`);

export const startMeeting = (meetingId) =>
  sendRequestAndGetResponse(`${BASE_PATH}/startMeeting`, {
    body: JSON.stringify({ meetingId }),
  });
export const createMeeting = (data:FinalizedCreateMeetingParams) =>
  sendRequestAndGetResponse(`${BASE_PATH}/createMeeting`, {
    body: JSON.stringify(data),
  });

export const isZoomPro = () =>
  sendRequestAndGetResponse(`${BASE_PATH}/is-zoom-pro`, { method: 'GET' });

export const disconnect = () =>
  sendRequestAndGetResponse(`${BASE_PATH}/disconnect`, { method: 'POST' });
