import cookie from 'cookie';
import * as mobx from 'mobx';
import { action, IObservableArray, makeObservable, observable } from 'mobx';
import { enableStaticRendering } from 'mobx-react';
import io from 'socket.io-client';

import {
  addTeamApiMethod,
  getTeamInvitationsApiMethod,
} from '../api/team-leader';
import {
  getTeamListApiMethod,
  getTeamMembersApiMethod,
} from '../api/team-member';

import Router from 'next/router';
import { Meeting } from './meeting';
import { Team } from './team';
import { User } from './user';

enableStaticRendering(typeof window === 'undefined');

mobx.configure({ enforceActions: 'observed' });

class Store {
  public isServer: boolean;
  public isMobile: boolean;

  public currentUser?: User = null;
  public currentMeeting?: Meeting = null;
  public currentUrl = '';

  public currentTeam?: Team = null;

  public teams: IObservableArray<Team> = observable([]);

  public socket: SocketIOClient.Socket;

  constructor({
    initialState = {},
    isServer,
    socket = null,
    isMobile,
  }: {
    initialState?: any;
    isServer: boolean;
    socket?: SocketIOClient.Socket;
    isMobile: boolean;
  }) {
    makeObservable(this, {
      currentUser: observable,
      currentUrl: observable,
      currentTeam: observable,

      changeCurrentUrl: action,
      setCurrentUser: action,
      setCurrentTeam: action,
    });
    this.isServer = !!isServer;
    this.isMobile = !!isMobile;

    this.setCurrentUser(initialState.user);

    this.currentUrl = initialState.currentUrl || '';

    if (
      initialState.teamSlug ||
      (initialState.user && initialState.user.defaultTeamSlug)
    ) {
      this.setCurrentTeam(
        initialState.teamSlug || initialState.user.defaultTeamSlug,
        initialState.teams
      );
    }

    this.socket = socket;

    if (socket) {
      socket.on('connect', () => {
        console.log('socket: ## connected', socket.id);
      });

      socket.on('connect_error', (e) => {
        if (
          !(socket as any).auth &&
          this.currentUrl == '/login' &&
          this.currentUser == null
        ) {
          //check for matching secret code on server
          (socket as any).auth = { tempRequest: 'foo' };
          socket.connect();
        } else {
          console.log('socket: ## connection_error', e);
        }
      });

      socket.on('disconnect', (e) => {
        console.log('socket: ## disconnected', e);
      });

      socket.on('loginToken', (token) => {
        Router.push(`/auth-login?token=${token}`);
      });

      socket.on('reconnect', (attemptNumber) => {
        console.log('socket: $$ reconnected', attemptNumber);
      });

      socket.on('unauthorized', (error, callback) => {
        if (
          error.data.type == 'UnauthorizedError' ||
          error.data.code == 'invalid_token'
        ) {
          // redirect user to login page perhaps or execute callback:
          callback();
          // TODO: Shall we redirect the user on login screen??
          console.log('User token has expired');
        }
      });
    }
  }

  public changeCurrentUrl(url: string) {
    this.currentUrl = url;
  }

  public async setCurrentUser(user) {
    if (user) {
      this.currentUser = new User({ store: this, ...user });
    } else {
      this.currentUser = null;
    }
  }

  public async setCurrentMeeting(meeting) {
    if (meeting) {
      this.currentMeeting = new Meeting({ store: this, ...meeting });
    } else {
      this.currentMeeting = null;
    }
  }

  public async addTeam({
    name,
    avatarUrl,
  }: {
    name: string;
    avatarUrl: string;
  }): Promise<Team> {
    const data = await addTeamApiMethod({ name, avatarUrl });
    const team = new Team({ store: this, ...data });

    return team;
  }

  public async setCurrentTeam(slug: string, initialTeams: any[]) {
    if (this.currentTeam) {
      if (this.currentTeam.slug === slug) {
        return;
      }
    }

    let found = false;

    const teams = initialTeams || (await getTeamListApiMethod()).teams;

    for (const team of teams) {
      if (team.slug === slug) {
        found = true;
        this.currentTeam = new Team({ ...team, store: this });

        const users =
          team.initialMembers ||
          (await getTeamMembersApiMethod(this.currentTeam._id)).users;

        const invitations =
          team.initialInvitations ||
          (await getTeamInvitationsApiMethod(this.currentTeam._id)).invitations;

        this.currentTeam.setInitialMembersAndInvitations(users, invitations);

        break;
      }
    }

    if (!found) {
      this.currentTeam = null;
    }
  }

  public addTeamToLocalCache(data): Team {
    const teamObj = new Team({ user: this.currentUser, store: this, ...data });
    this.teams.unshift(teamObj);

    return teamObj;
  }

  public editTeamFromLocalCache(data) {
    const team = this.teams.find((item) => item._id === data._id);

    if (team) {
      if (data.memberIds && data.memberIds.includes(this.currentUser._id)) {
        team.changeLocalCache(data);
      } else {
        this.removeTeamFromLocalCache(data._id);
      }
    } else if (
      data.memberIds &&
      data.memberIds.includes(this.currentUser._id)
    ) {
      this.addTeamToLocalCache(data);
    }
  }

  public removeTeamFromLocalCache(teamId: string) {
    const team = this.teams.find((t) => t._id === teamId);

    this.teams.remove(team);
  }
}

let store: Store = null;

function initializeStore(initialState = {}) {
  const isServer = typeof window === 'undefined';
  const { isMobile } = initialState as any;
  let socket;
  if (!isServer) {
    const cookieObj = document.cookie && cookie.parse(document.cookie);
    const token =
      cookieObj && cookieObj.token && `${cookie.parse(document.cookie).token}`;
    console.log(token);
    socket = isServer
      ? null
      : io(process.env.URL_API, {
          query: token ? `token=${token}` : '',
          transports: ['websocket'],
          upgrade: true,
          timeout: 50000,
        });
  }

  const _store =
    store !== null && store !== undefined
      ? store
      : new Store({ initialState, isServer, socket, isMobile });

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') {
    return _store;
  }
  // Create the store once in the client
  if (!store) {
    store = _store;
  }

  return _store;
}

function getStore() {
  return store;
}

export { Store, initializeStore, getStore };
