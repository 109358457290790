import {
  update,
  updateImageApiMethod,
  updatePhotos,
  updateTopic
} from "lib/api/meeting";
import * as _ from "lodash";
import { makeObservable, observable, runInAction } from "mobx";
import { MEETING_STATE, PaymentType, RateType } from "utils/constants";

class Member {
  hasAuthorizedPayment: boolean;
  userId: string;
  hasCapturedPayment: boolean;
}

export interface Externals {
  join_url: string;
}

class Meeting {
  public _id: string;
  public scheduledAt: Date;
  public rate: number = null;
  public fee: number;
  public billTo: string;
  public duration: number; // stored in seconds
  public topic: string = null;
  public type: string;
  public enableRecording: boolean;
  public state: string;
  public classSize: number;
  public isPrivate: boolean;
  public members: Member[];
  public createdBy: string;
  public backgroundImage: string = null;
  public customConfirmationMessage: string = null;
  public requirements: string;
  public learningGoals: string = null;
  public externals: Externals;
  public invoice: any;
  public createdAt: Date;
  public spotsLeft?: number;
  public attendees?: Record<string, any>[];
  public photos?: { url: string; order: number }[] = [];

  public rateType: RateType;
  public paymentType: PaymentType;

  constructor(params) {
    makeObservable(this, { 
      backgroundImage: observable,
       topic: observable, 
       photos: observable, 
       customConfirmationMessage: observable,
       learningGoals: observable,
       rate: observable
       });
    this._id = params._id;
    this.scheduledAt = params.scheduledAt;
    this.createdAt = params.createdAt;


    this.isPrivate = params.isPrivate;
    this.duration = params.duration;
    this.topic = params.topic;
    this.type = params.type;
    this.enableRecording = params.enableRecording;

    this.state = params.state;
    this.classSize = params.classSize;
    this.createdBy = params.createdBy;
    this.backgroundImage = params.backgroundImage;
    this.externals = params.externals;
    this.customConfirmationMessage = params.customConfirmationMessage;
    this.requirements = params.customConfirmationMessage;
    this.learningGoals = params.learningGoals;
    this.spotsLeft = params.spotsLeft;

    this.members = params.members;
    this.attendees = params.attendees;

    this.invoice = params.invoice;
    this.photos = params.photos;

    this.rateType = params.billingInfo.rateType;
    this.paymentType = params.billingInfo.paymentType;
    this.billTo = params.billingInfo.billTo;
    this.rate = params.billingInfo.rate;

    this.fee = params.fee || 0; //virtual field on meeting to be able to charge a fee if someone requests a recording....

  }

  public durationMinutes() {
    return this.duration / 60 || 0;
  }

  public isFinished() {
    return this.state === MEETING_STATE.FINISHED;
  }


  public async updateImage(newUrl: string) {
    const { updatedUrl } = await updateImageApiMethod(this._id, newUrl);

    runInAction(() => {
      this.backgroundImage = updatedUrl;
    });
  }

  public async changeTopic(newTopic: string) {
    const updatedTopic = await updateTopic(this._id, newTopic);

    runInAction(() => {
      this.topic = updatedTopic;
    });
  }

  public async changeClassSize(requestedClassSize: number) {
    const response = await update(this._id, { classSize: requestedClassSize });

    runInAction(() => {
      this.classSize = response.classSize;
    });
  }

  public async updatePhotos(update: any) {
    const { photos } = await updatePhotos(this._id, update);
    runInAction(() => {
      this.photos = photos;
    });
  }

  public static getCreatorInfoFromMeeitng(
    meeting: Meeting,
    attendees: Record<string, any>[]
  ): Record<string, any> {
    const creatorInfo = _.find(attendees, (a) => meeting.createdBy == a._id);
    return creatorInfo;
  }
}

export { Meeting };
